<template>
  <div class="page-login d-flex align-items-center justify-content-center">
    <div class="login-container d-flex align-items-center justify-content-left">
      <div class="login-image">
        <img src="img/logo.png" />
      </div>

      <div class="login-form">
        <h1>Login</h1>

        <ValidationObserver ref="observer">
          <form
            slot-scope="{ validate }"
            @submit.prevent="validate().then(handleSubmit)"
          >
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="email">Email</label>
                <input
                  class="form-control"
                  type="email"
                  id="email"
                  name="Email"
                  aria-describedby="email-feedback"
                  placeholder=""
                  autocomplete="email"
                  v-model="form.email"
                />
                <small class="feedback text-warning" id="email-feedback">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </div>

            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="password">Password</label>
                <input
                  class="form-control"
                  type="password"
                  id="password"
                  name="Password"
                  aria-describedby="password-feedback"
                  placeholder=""
                  autocomplete="current-password"
                  v-model="form.password"
                />
                <small class="feedback text-warning" id="password-feedback">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </div>

            <div class="options d-flex align-items-start justify-content-left">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="rememberMe"
                />
                <label class="form-check-label" for="rememberMe"
                  >Remember me</label
                >
              </div>
              <button
                :disabled="loading"
                type="submit"
                class="btn btn-primary w-100"
              >
                Login
              </button>
              <a href="#">Forgot password?</a>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapState, mapActions } from "vuex";
import axios from "axios";
import qs from "qs";

const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:1337";

export default {
  name: "Login",

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      loading: (state) => state.app.loading,
    }),

    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated",
    }),
  },

  methods: {
    ...mapActions({
      startLoading: "app/startLoading",
      stopLoading: "app/stopLoading",
      login: "auth/login",
    }),

    async handleSubmit() {
      try {
        await this.startLoading();

        const loginResponse = await axios.post(
          `${apiUrl}/auth/local`,
          qs.stringify({
            identifier: this.form.email,
            password: this.form.password,
          })
        );

        await this.login({
          jwt: loginResponse.data.jwt,
          user: loginResponse.data.user,
          admin: false,
        });

        this.$router.push({
          name: "Quizzes",
        });
      } catch (error) {
        console.log({ error });
      } finally {
        await this.stopLoading();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// --breakpoint-xs: 0;
// --breakpoint-sm: 576px;
// --breakpoint-md: 768px;
// --breakpoint-lg: 992px;
// --breakpoint-xl: 1200px;

.page-login {
  background: #00338d;
  padding: 30px 16px;
  min-height: 100vh;

  @media (min-width: 1200px) {
    padding: 124px;
  }
}

.login-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  overflow: hidden;
  max-width: 382px;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    max-width: 1192px;
  }
}

.login-image {
  background-image: url("../../../public/img/login/main-mobile.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  height: 206px;
  width: 100%;
  position: relative;

  @media (min-width: 1200px) {
    background-image: url("../../../public/img/login/main.png");
    height: 546px;
    max-width: 517px;
    background-size: cover;
  }

  img {
    height: 56px;
    width: 66px;
    left: 16px;
    top: 15px;
    position: absolute;

    @media (min-width: 1200px) {
      height: 100px;
      width: 120px;
      left: 55px;
      top: 33px;
      border-radius: 0px;
    }
  }
}

.login-form {
  padding: 24px 16px 77px;
  width: 100%;

  @media (min-width: 1200px) {
    padding: 85px 105px 75px 105px;
  }

  .form-group {
    @media (min-width: 1200px) {
      margin-bottom: 23px;
    }
  }

  .feedback {
    position: absolute;
  }

  input {
    border: 0.5px solid #808080;
    min-height: 43px;
    font-size: 16px;
    line-height: 20px;

    &[type="checkbox"] {
      min-height: auto;
    }
  }

  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
    margin-bottom: 24px;

    @media (min-width: 1200px) {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 40px;
    }
  }

  .options {
    flex-direction: row;
    flex-wrap: wrap;

    .form-check {
      margin-top: 12px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;

      @media (min-width: 1200px) {
        margin-top: 0;
      }

      input {
        margin: 0;
      }

      label {
        margin: 0;
        padding-left: 20px;
        top: 1px;
        position: relative;
      }
    }

    button {
      margin-bottom: 24px;
      border-radius: 0;
      padding: 16px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;

      @media (min-width: 1200px) {
        order: 3;
      }
    }

    a {
      margin-bottom: 0;
      text-decoration: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: #00338d;
      margin: 0 auto;

      @media (min-width: 1200px) {
        order: 2;
        margin: 0;
        text-align: right;
        flex: 1 0 auto;
      }
    }
  }
}
</style>
